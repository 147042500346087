import React from 'react'
import { Link } from 'gatsby'
import logo from "../../assets/images/soda4LCA_logo_sm.png"
import footerBlur from "../../assets/images/shutterstock_661589125M_blur_crop.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color" style={{backgroundImage : `url(${footerBlur})`, backgroundRepeat : 'no-repeat', backgroundSize : 'cover'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <p>Commercial support, training and application hosting (Software as a Service, SaaS) is available through ok*worx.</p>

                            <ul className="social-link">
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Explore</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="#">
                                        About
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        Case Studies
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        Our Blog
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        Contact
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Resources</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="#">
                                        Our Scientists
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        Our Services
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        Testimonials
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        SaaS Solutions
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        Case Studies
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Get in touch</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i>

Oliver Kusche Research &amp; Consulting <br />
Marie-Curie-Str. 1 <br />
79100 Freiburg <br />
Germany
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href="tel:+4976131967967">+49 (0)761 31 967 967</a>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:info@okwrox.com">info [at] okworx.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>soda4LCA</strong> All rights reserved <a target="_blank" href="https://okworx.com/" rel="noreferrer">ok*worx</a></p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>
                                    <Link to="#">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        Terms &amp; Conditions
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                {/* <img src="" width="300px" alt="footer-logo" /> */}
            </div>
        </footer>
    );
}

export default Footer;