import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>soda4LCA</title>
                <meta name="description" content="soda4LCA - Oliver Kusche Research &amp; Consulting" />
                <meta name="og:title" property="og:title" content="soda4LCA - Oliver Kusche Research &amp; Consulting"></meta>
                <meta name="twitter:card" content="soda4LCA - Oliver Kusche Research &amp; Consulting"></meta>
                <link rel="canonical" href="https://soda4lca.io/"></link>
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />
                <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&amp;display=swap" rel="stylesheet"/>
            </Helmet>
        </div>
    )
}

export default SEO
